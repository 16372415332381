const firebaseConfig = {
  apiKey: "AIzaSyA2e0nv8gYrR8wk3E_2_5a3bNtzGT_uo-o",
  authDomain: "iowaht.firebaseapp.com",
  databaseURL: "https://iowaht.firebaseio.com",
  projectId: "iowaht",
  storageBucket: "iowaht.appspot.com",
  messagingSenderId: "92210107917",
  appId: "1:92210107917:web:9dbe7048bc1d34d743a744",
  measurementId: "G-RTGBPJ82ZK"
};

export default firebaseConfig;