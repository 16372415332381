import React, { Component } from "react"
import PropTypes from "prop-types"
import { Link, navigate } from "gatsby"
import { MDBNavbar, MDBBtn, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavbarToggler, MDBCollapse, MDBContainer, MDBIcon, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact"
import LogoImage from './LogoImage';
import './navbar.scss'
import { FirebaseContext } from "../Firebase";
class Navbar extends Component {
  static contextType = FirebaseContext;

  state = {
    isOpen: false,
  }

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen })
  }
  handleLogout = () => {
    const { firebase } = this.context;
    firebase.logout().then(() => {
      navigate('/login')
    })
  }

  render() {
    const { user } = this.context;
    //console.log(loading, firebase, user);
    //console.log(this.context);
    return (
      <div className="container-fluid px-0 iNavbar">
        <MDBNavbar color="orange darken-2" dark expand="md" id="top-nav">
          <MDBContainer className="px-0">
            <MDBNavbarBrand className="mx-0 py-0">
              <LogoImage />
            </MDBNavbarBrand>
            <MDBNavbarBrand className="mx-0 py-0">
              <Link to="/">
                <strong className="white-text text-uppercase">{this.props.siteTitle}</strong>
              </Link>
            </MDBNavbarBrand>
            <MDBNavbarToggler onClick={this.toggleCollapse} style={{ display: 'none' }} />
            <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
              <MDBNavbarNav right>
                {!user &&
                  <>
                    <MDBNavItem>
                      <a href="/login" className="nav-link">
                        <strong style={{ display: 'none' }}> Login</strong>
                      </a>
                    </MDBNavItem>
                  </>
                }
                {!!user && !!user.email &&
                  <MDBNavItem >
                    <span className="float-left  nav-link">Hello <strong>{user.email} !</strong></span>
                    <a href="#!" className="nav-link float-left" onClick={this.handleLogout}>
                      Logout
                    </a>
                  </MDBNavItem>
                }

              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
        <MDBNavbar color="orange darken-1 py-0" dark expand="md" id="main-nav">
          <MDBContainer className="px-0">

            <MDBNavbarToggler onClick={this.toggleCollapse} />
            <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
              <MDBNavbarNav left>
                <MDBNavItem>
                  <Link to="/" className="nav-link" activeClassName="active">
                    HOME
                  </Link>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      <MDBIcon icon="" className="mr-1" /> EVENTS
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-default" right>
                      <MDBDropdownItem href="/events">ALL</MDBDropdownItem>
                      <MDBDropdownItem href="/bramha">BRAHMOTSAVAM</MDBDropdownItem>
                      <MDBDropdownItem href="/calender">CALENDAR</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <Link to="/services" className="nav-link" activeClassName="active">
                    SERVICES
                  </Link>
                </MDBNavItem>
                <MDBNavItem>
                  <Link to="/poojareq" className="nav-link" activeClassName="active">
                    POOJA REQUEST
                  </Link>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      <MDBIcon icon="" className="mr-1" /> FORMS
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-default" right>
                      <MDBDropdownItem href="/forms">ALL</MDBDropdownItem>
                     <MDBDropdownItem href="/botform">TRUSTEE ACCEPTANCE</MDBDropdownItem>
                      <MDBDropdownItem href="/poojareq">POOJA REQUEST</MDBDropdownItem>
                      <MDBDropdownItem href="/newsletter">NEWS LETTER SIGNUP</MDBDropdownItem>
                      <MDBDropdownItem href="/volunteersignup">VOLUNTEER SIGNUP</MDBDropdownItem>
                     <MDBDropdownItem href="/ldform">LAND DONATION</MDBDropdownItem>
                     <MDBDropdownItem href="/bramhasponsor">BRAHMOTSAVAM SPONSOR</MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <Link to="/vidhya" className="nav-link" activeClassName="active">
                    VIDHYAA
                  </Link>
                </MDBNavItem>
                <MDBNavItem>
                  <MDBDropdown>
                    <MDBDropdownToggle nav caret>
                      <MDBIcon icon="" className="mr-1" /> OUR TEMPLE
                    </MDBDropdownToggle>
                    <MDBDropdownMenu className="dropdown-default" right>
                      <MDBDropdownItem href="/ourtemple">ABOUT</MDBDropdownItem>
                      <MDBDropdownItem href="/incorporation">INCORPORATION</MDBDropdownItem>
                      <MDBDropdownItem href="/bylaws">BY LAWS</MDBDropdownItem>
                      <MDBDropdownItem href="/guidelines">GUIDELINES</MDBDropdownItem>
                      <MDBDropdownItem href="/ourpriest">OUR PRIEST</MDBDropdownItem>
                      <MDBDropdownItem href="/current">CURRENT COMITTEES </MDBDropdownItem>
                      <MDBDropdownItem href="/past">PAST COMITTEES </MDBDropdownItem>
                    </MDBDropdownMenu>
                  </MDBDropdown>
                </MDBNavItem>
                <MDBNavItem>
                  <Link to="/newtemple" className="nav-link" activeClassName="active">
                    NEW TEMPLE
                  </Link>
                </MDBNavItem>
                <MDBNavItem className="my-auto">
                  <MDBBtn color="white" size="sm" className="m-0 p-1" rounded>
                    <Link to="/donate" className="nav-link donate p-0" activeClassName="active">
                      Donate
                    </Link>
                  </MDBBtn>
                </MDBNavItem>
              </MDBNavbarNav>
            </MDBCollapse>
          </MDBContainer>
        </MDBNavbar>
      </div>

    )
  }
}
Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: ``,
}

export default Navbar
