import "@fortawesome/fontawesome-free/css/all.min.css"
import "bootstrap-css-only/css/bootstrap.min.css"
import "./src/styles/scss/mdb.scss"
import React from 'react';
import Layout from './src/components/layout';

export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>{element}</Layout>;
};

//export const registerServiceWorker = () => true;

export const onClientEntry = () => {
    window.onload = () => {
        if (Notification.permission !== "default" || Notification.permission !== "denied") {
            // Notification.requestPermission().then(function (permission) {
            //     console.log(permission);

            // })
        }
    }
}