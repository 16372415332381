import React from "react";
import { MDBCol, MDBContainer, MDBRow, MDBFooter, MDBIcon } from "mdbreact";
import './footer.scss';
const FooterPage = () => {
  return (
    <MDBFooter color="orange" className="font-small pt-4 " id="footer">
      <MDBContainer fluid className="text-center text-md-left pb-4">
        <MDBRow>
          <MDBCol md="4" >
            <MDBCol>
              <h5 className="title"><strong>Address & Contact</strong></h5>
              <hr className="footerLine" align="left"></hr>
            </MDBCol>

            <MDBRow>
              <MDBCol md="6">
                <div id="map-container"
                  className="rounded z-depth-1-half map-container"
                  style={{ height: "150px" }} >
                  <iframe
                    src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJwell5Db55IcRB-GxCFwn7Ig&key=AIzaSyDTL6n0wwXGh8bAXa2NTLfYCHtLsBKNAcQ"
                    title="Hindu Temple, Cedar Rapids IA"
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    style={{ border: 0 }}
                  />
                </div>
              </MDBCol>
              <MDBCol md="6">
                <address className="font-weight-bolder">
                  1700 Naoma Dr SW,
                  <br />
                  Cedar Rapids,IA 52404
                  <br />
                  <MDBIcon fas="true" icon="phone-alt" /><strong> +1-(319)-396-3196 </strong>
                  <br />
                  <strong style={{ whiteSpace: "wrap" }}> econtact@iowahindutemple.org</strong>
                </address>

              </MDBCol>
            </MDBRow>
          </MDBCol>
          <MDBCol md="4">
            <MDBCol>
              <h5 className="title"><strong>Hours</strong></h5>
              <hr className="footerLine" align="left"></hr>
            </MDBCol>
            <h5 className="title"><strong>Monday - Friday</strong></h5>
            <p className="font-weight-bolder">
              10 AM-12:30 PM|5:30 PM-8 PM (Mar - Oct)|5:30 PM-7:30 PM (Nov - Feb)
            </p>
            <h5 className="title"><strong>CLOSED ON THURSDAYS</strong></h5>
            <h5 className="title"><strong>Saturday/Sunday</strong></h5>
            <p className="font-weight-bolder">
              10 AM-1 PM|5:30 PM-8 PM (Mar - Oct)|5:30 PM-7:30 PM (Nov - Feb)
            </p>
          </MDBCol>
          <MDBCol md="4">
            <MDBCol>
              <h5 className="title"><strong>Donate</strong></h5>
              <hr className="footerLine" align="left"></hr>
            </MDBCol>
            <MDBRow>
              <MDBCol md="12" className="mx-auto">
                <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                  <input type="hidden" name="cmd" value="_s-xclick" />
                  <input type="hidden" name="hosted_button_id" value="RRMAMJJLD2XWA" />
                  <input type="image" src="https://iowaht.s3-us-east-2.amazonaws.com/images/donate2.png" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" style={{ margin: "20px", display: "block", maxWidth: "200px" }} />
                  <img alt="" border="0" src="https://www.paypal.com/en_US/i/scr/pixel.gif" width="1" height="1" />
                </form>
              </MDBCol>
            </MDBRow>
            <MDBCol>
              {/* <iframe
                src="https://www.mypanchang.com/mobilewidget.php?cityname=CedarRapids-Iowa-USA&displaymode=full&color=FFFFE0"
                scrolling="no" frameBorder="0"
                style={{ border: "none" }}>
              </iframe> */}
            </MDBCol>
            <MDBCol>
              <h5 className="title"><strong>Follow Us</strong></h5>
              <hr className="footerLine" align="left"></hr>
              <MDBCol md="12" lg="12" className="ml-lg-0">
                <div className="text-center text-md-left">
                  <ul className="list-unstyled list-inline">
                    <li className="list-inline-item">
                      <a className="btn-floating btn-sm rgba-white-slight mx-1" href="https://www.facebook.com/iowahindutemple" target="_blank" rel="noopener noreferrer">
                        <i className="fab fa-facebook-f" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a className="btn-floating btn-sm rgba-white-slight mx-1" href="https://www.youtube.com/c/HinduTempleAssociationofEasternIowa" target="_blank" rel="noopener noreferrer" >
                        <i className="fab fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </MDBCol>
            </MDBCol>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
      <div className="footer-copyright text-center py-3 orange darken-2">
        <MDBContainer fluid>
          <p> &copy; {new Date().getFullYear()} Copyright: <a href="https://www.iowahindutemple.org"> iowahindutemple.org </a> <a href="#!" className="mx-auto "> | Disclaimer </a> <a href="#!" className="mx-auto ">|  Privacy Policy </a></p>
        </MDBContainer>
      </div>
    </MDBFooter>
  );
}

export default FooterPage;