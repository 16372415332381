/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Navbar from "./Header/navbar"
import FooterPage from './Footer/footer';
import { FirebaseContext, useAuth } from './Firebase';
import './layout.scss'

const Layout = ({ children }) => {
  const { user, firebase, loading } = useAuth();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <FirebaseContext.Provider value={{ user, firebase, loading }}>
      <div className="site">
        <Navbar siteTitle={data.site.siteMetadata.title} />
        <main className="site-content">{children}</main>
        <FooterPage />
      </div>
    </FirebaseContext.Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
