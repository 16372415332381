// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-admin-index-js": () => import("./../src/pages/admin/index.js" /* webpackChunkName: "component---src-pages-admin-index-js" */),
  "component---src-pages-botform-js": () => import("./../src/pages/botform.js" /* webpackChunkName: "component---src-pages-botform-js" */),
  "component---src-pages-bramha-js": () => import("./../src/pages/bramha.js" /* webpackChunkName: "component---src-pages-bramha-js" */),
  "component---src-pages-bramhasponsor-js": () => import("./../src/pages/bramhasponsor.js" /* webpackChunkName: "component---src-pages-bramhasponsor-js" */),
  "component---src-pages-bylaws-js": () => import("./../src/pages/bylaws.js" /* webpackChunkName: "component---src-pages-bylaws-js" */),
  "component---src-pages-calender-js": () => import("./../src/pages/calender.js" /* webpackChunkName: "component---src-pages-calender-js" */),
  "component---src-pages-current-js": () => import("./../src/pages/current.js" /* webpackChunkName: "component---src-pages-current-js" */),
  "component---src-pages-donate-js": () => import("./../src/pages/donate.js" /* webpackChunkName: "component---src-pages-donate-js" */),
  "component---src-pages-events-js": () => import("./../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-forms-js": () => import("./../src/pages/forms.js" /* webpackChunkName: "component---src-pages-forms-js" */),
  "component---src-pages-guidelines-js": () => import("./../src/pages/guidelines.js" /* webpackChunkName: "component---src-pages-guidelines-js" */),
  "component---src-pages-incorporation-js": () => import("./../src/pages/incorporation.js" /* webpackChunkName: "component---src-pages-incorporation-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ldform-js": () => import("./../src/pages/ldform.js" /* webpackChunkName: "component---src-pages-ldform-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-newsletter-js": () => import("./../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-newtemple-js": () => import("./../src/pages/newtemple.js" /* webpackChunkName: "component---src-pages-newtemple-js" */),
  "component---src-pages-ourpriest-js": () => import("./../src/pages/ourpriest.js" /* webpackChunkName: "component---src-pages-ourpriest-js" */),
  "component---src-pages-ourtemple-js": () => import("./../src/pages/ourtemple.js" /* webpackChunkName: "component---src-pages-ourtemple-js" */),
  "component---src-pages-past-js": () => import("./../src/pages/past.js" /* webpackChunkName: "component---src-pages-past-js" */),
  "component---src-pages-poojareq-js": () => import("./../src/pages/poojareq.js" /* webpackChunkName: "component---src-pages-poojareq-js" */),
  "component---src-pages-services-js": () => import("./../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-vidhya-js": () => import("./../src/pages/vidhya.js" /* webpackChunkName: "component---src-pages-vidhya-js" */),
  "component---src-pages-volunteersignup-js": () => import("./../src/pages/volunteersignup.js" /* webpackChunkName: "component---src-pages-volunteersignup-js" */)
}

